/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { FormattedMessage } from "shared/helpers/i18n/useIntl";
import { jsx, Themed } from "theme-ui";

import type { WebsiteNameQuery } from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";
import messages from "./i18n";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetAccessibilityInfo" }>;
}

const AccessibilityInfoWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const data = useStaticQuery<WebsiteNameQuery>(graphql`
    query WebsiteName {
      settings {
        name
      }
    }
  `);

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <FormattedMessage
        {...messages.message}
        values={{
          theaterName: data.settings?.name,
          p: (message: string) => <Themed.p>{message}</Themed.p>,
        }}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetAccessibilityInfo on WidgetAccessibilityInfo {
    id
    __typename
  }
`;

export default memo(AccessibilityInfoWidget);
