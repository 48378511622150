import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  message: {
    id: "widget.accessibility-info.displayed-message",
    defaultMessage:
      "<p>We believe that {theaterName} site should be accessible to all people and comply with the requirements of the Web Content Accessibility Guidelines.</p><p>Embracing these standards is an ongoing effort. We are committed to improve our site to meet the WCAG 2.0 AA guidelines. If you have questions about its compliance or need help with its processes, please contact us.</p>",
  },
});

export default messages;
